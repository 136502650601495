const apiConfig = {
  api_url: process.env.NEXT_PUBLIC_API_ENDPOINT,
  cloudFrontUrl: process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
  cloudFrontWPUrl: process.env.NEXT_PUBLIC_CLOUDFRONT_WP_URL,
  api_url_server_side: process.env.NEXT_PUBLIC_API_ENDPOINT_SERVER_SIDE,
  blog_api_url: process.env.NEXT_PUBLIC_BLOG_URL,
  domain: process.env.NEXT_PUBLIC_WEB_URL,
};

export default apiConfig;
